<!-- @format -->

<template>
	<div>
		<div class="margin-top-20">
			<SubSummaryPurchaseInvoiceHeaders />
		</div>
		<div
			class="margin-top-20"
			:class="showPreview ? 'disable-div' : 'enable-div'"
		>
			<CurrentPurchaseInvoiceEdit />
		</div>
		<div class="margin-top-20">
			<b-button class="is-danger" @click="onClose">Close</b-button>
		</div>
	</div>
</template>

<script>
import SubSummaryPurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/sub-summary/SubSummaryPurchaseInvoiceHeaders.vue'
import CurrentPurchaseInvoiceEdit from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/sub-components/CurrentPurchaseInvoiceEdit.vue'
import storePurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/usePurchaseInvoiceState'
export default {
	name: 'PreviewSelectedPurchaseInvoice',
	props: {
		refetchData: {
			type: Function,
		},
		closePreview: {
			type: Function,
		},
	},
	components: {
		SubSummaryPurchaseInvoiceHeaders,
		CurrentPurchaseInvoiceEdit,
	},
	setup(props) {
		const onClose = () => {
			props.closePreview()
			props.refetchData()
		}
		const { showPreview } = storePurchaseInvoice()
		return { onClose, showPreview }
	},
}
</script>

<style lang="scss" scoped></style>
