<!-- @format -->

<template>
	<div>
		<div class="margin-top-20">
			<SubSummaryPurchaseInvoiceHeaders />
		</div>
		<div class="margin-top-20">
			<CurrentPurchaseInvoiceEdit />
		</div>
		<div class="margin-top-20">
			<SearchStockItemToAddToPurchaseInvoiceEdit />
		</div>
		<div
			style="
				background-color: rgb(76, 92, 122);
				padding-bottom: 30px;
				color: white;
				padding-top: 20px;
				padding-right: 30px;
				text-align: right;
				font-weight: 700;
			"
		>
			<b-button class="is-primary btn" @click="onSave">Close</b-button>
			<b-button class="is-info btn" @click="onLock"> Lock & Close </b-button>
		</div>
		<div class="max-width-150">
			<AddPurchaseInvoiceLinesModalFrame
				v-if="isModalVisible"
				@close="closeModal"
				:key="keyModal"
			/>
		</div>
	</div>
</template>

<script>
import SubSummaryPurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/sub-summary/SubSummaryPurchaseInvoiceHeaders.vue'
import CurrentPurchaseInvoiceEdit from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/sub-components/CurrentPurchaseInvoiceEdit.vue' // ****
import SearchStockItemToAddToPurchaseInvoiceEdit from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/sub-components/SearchStockItemToAddToPurchaseInvoiceEdit.vue'
import storePurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/usePurchaseInvoiceState'
import AddPurchaseInvoiceLinesModalFrame from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/add-purchase-invoice-lines/AddPurchaseInvoiceLinesModalFrame.vue'
// import EditPurchaseInvoiceLinesModalFrame from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/sub-components/edit-purchase-invoice-lines/EditPurchaseInvoiceLinesModalFrame.vue'
// import usePurchaseInvoiceModalStateEdit from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/add-purchase-invoice-lines-edit/usePurchaseInvoiceModalStateEdit.js'
import usePurchaseInvoiceModalState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/add-purchase-invoice-lines/usePurchaseInvoiceModalState.js'
import SetLockedPurchaseInvoiceMutation from '@/_srcv2/pages/purchase-invoice/_shared/SetLockedPurchaseInvoiceMutation.graphql'
import { useMutation } from '@vue/apollo-composable'
import { computed } from '@vue/composition-api'
import Store from '@/store'
import storePurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState.js'

export default {
	name: 'EditSelectedPurchaseInvoice',
	props: {
		refetchData: {
			type: Function,
		},
	},
	components: {
		SubSummaryPurchaseInvoiceHeaders,
		CurrentPurchaseInvoiceEdit,
		SearchStockItemToAddToPurchaseInvoiceEdit,
		AddPurchaseInvoiceLinesModalFrame,
	},
	setup(props) {
		const { purchaseInvoiceSupplierNumber } = storePurchaseInvoiceHeaders()
		const { isModalVisible, keyModal } = usePurchaseInvoiceModalState()
		const { showSearchBox, showSelectPurchaseInvoiceHeadersDetails } =
			storePurchaseInvoice()
		const onSave = () => {
			props.refetchData()
			showSearchBox.value = true
			showSelectPurchaseInvoiceHeadersDetails.value = false
		}
		// todo ----------------------------------------------------------------
		const mutationVariable = computed(() => {
			return {
				invoice_number: purchaseInvoiceSupplierNumber.value,
				our_company: Store.getters.getUserCurrentCompany,
			}
		})
		const { mutate, onDone } = useMutation(
			SetLockedPurchaseInvoiceMutation,
			() => ({
				variables: mutationVariable.value,
			}),
		)
		const onLock = () => {
			mutate()
		}
		onDone(() => {
			props.refetchData()
			showSearchBox.value = true
			showSelectPurchaseInvoiceHeadersDetails.value = false
		})
		const closeModal = () => (isModalVisible.value = false)
		return { onSave, onLock, isModalVisible, closeModal, keyModal }
	},
}
</script>

<style lang="scss" scoped>
.btn {
	width: 200px;
	margin-left: 30px;
}
</style>
