<template>
	<div>
		<div>
			<b-tag
				type="is-info"
				size="is-large"
				class="margin-top-5"
				style="width: 420px"
			>
				Current Purchase Invoice
			</b-tag>
		</div>
		<div v-if="true">
			<div class="margin-top-20">
				<ve-table
					:rows="purchaseInvoiceLinesArray"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:searchedOptions="false"
					:perPage="perPageProp"
					maxHeight="1500px"
				>
				</ve-table>
			</div>
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 30px;
					color: white;
					padding-top: 20px;
					padding-right: 30px;
					text-align: right;
					font-weight: 700;
				"
			>
				Purchase Invoice Total: {{ purchaseInvoiceTotal }}
				{{ purchaseInvoiceExchangeUnit }}
			</div>
		</div>
	</div>
</template>

<script>
import GetCurrentPurchaseInvoiceLinesSubscription from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/graphql/GetCurrentPurchaseInvoiceLinesSubscription.graphql'
import storePurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState.js'
import { useSubscription, useMutation } from '@vue/apollo-composable'
import storeCurrentPurchaseInvoiceEdit from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/sub-components/edit-purchase-invoice-lines/useCurrentPurchaseInvoiceEditState.js'
import { ref, reactive, computed } from '@vue/composition-api'
import DeletePurchaseInvoiceLineMutation from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/graphql/DeletePurchaseInvoiceLine.mutation.graphql'
import numeral from 'numeral'

export default {
	name: 'CurrentPurchaseInvoiceEdit',
	setup() {
		const perPageProp = ref(25)
		const { columnsList, purchaseInvoiceLinesArray } =
			storeCurrentPurchaseInvoiceEdit()
		const onRowDoubleClick = (params) => {
			console.log(params.row)
		}
		// *---------------------------------------------------------------
		const { purchaseInvoiceSupplierNumber, purchaseInvoiceExchangeUnit } =
			storePurchaseInvoiceHeaders()
		const { onResult } = useSubscription(
			GetCurrentPurchaseInvoiceLinesSubscription,
			() => ({
				invoice_number: purchaseInvoiceSupplierNumber.value,
			}),
		)
		// todo --------------------------------------------------------------------------------
		// ? regex statements
		// ? Use a regular expression to match spaces and commas globally
		const regex = /[\s]/g
		// eslint-disable-next-line no-useless-escape
		const regexComa = /[\,]/g
		// eslint-disable-next-line no-useless-escape
		const regexDot = /[\.]/g
		// todo -----------------------------------------------------------------
		const getPriceInExchangeFormatted = (payload) => {
			// ? Replace all matches with an empty string
			const newString = payload.replace(regex, '').replace(regexComa, '.')
			console.log('newString', newString)
			console.log('typeof newString', typeof newString)
			return numeral(parseFloat(newString))
				.format('0,0.00')
				.replace(regexComa, ' ')
				.replace(regexDot, ',')
		}

		// todo -----------------------------------------------------------------
		const getLineTotal = (payload) => {
			console.log('payload', payload)
			console.log('typeof payload', typeof payload)
			return numeral(payload / 100)
				.format('0,0.00')
				.replace(regexComa, ' ')
				.replace(regexDot, ',')
		}
		// todo -----------------------------------------------------------------
		onResult((result) => {
			purchaseInvoiceLinesArray.value = result.data.goods_transactions.map(
				(item) => {
					console.log('item', item)
					return {
						goods_transaction_id: item.goods_transaction_id,
						transaction_type: item.transaction_type,
						invoice_number: item.invoice_number,
						stock_id: item.stock_id,
						line_info: item.line_info,
						amount_debit: item.amount_debit,
						unit_price: item.unit_price,
						vat_percent: item.vat_percent,
						vat_debit: item.vat_debit,
						invoice_price: item.invoice_price,
						line_price_total_debit: item.line_price_total_debit,
						vat_debit_exchange: item.vat_debit_exchange,
						invoice_price_exchange: item.invoice_price_exchange,
						line_price_total_debit_exchange:
							item.line_price_total_debit_exchange,
						purchaseInvoiceExcUnit: purchaseInvoiceExchangeUnit.value,
						// ? -------------------------------------------------------------
						// supplierInvoicePriceExchange: item.supplierInvoicePriceExchange,
						lineTotal: getLineTotal(item.line_price_total_debit_exchange),
						supplierInvoicePriceExchangeFormatted: getPriceInExchangeFormatted(
							(item.invoice_price_exchange / 100).toFixed(2),
						),
					}
				},
			)
		})
		// *---------------------------------------------------------------------
		const mutationVariable = reactive({
			goods_transaction_id: '',
			stock_id: '',
			debit_amount: 0,
		})
		const { mutate: deletePurchaseInvoiceLine } = useMutation(
			DeletePurchaseInvoiceLineMutation,
			() => ({
				variables: mutationVariable,
			}),
		)
		const setMutationVariablesPromise = (paramsRow) => {
			return new Promise((resolve, reject) => {
				if (paramsRow) {
					mutationVariable.goods_transaction_id = paramsRow.goods_transaction_id
					mutationVariable.stock_id = paramsRow.stock_id
					mutationVariable.debit_amount = paramsRow.amount_debit * -1
					resolve(mutationVariable)
				} else {
					const error = new Error('Mutation variables is not valid')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}

		// *---------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'delete') {
				console.log(params.row)
				setMutationVariablesPromise(params.row).then(() => {
					deletePurchaseInvoiceLine().then(() => {
						alert('Line is deleted')
					})
				})
			}
		}
		// todo --------------------------------------------------------------------
		const purchaseInvoiceTotal = computed(() => {
			return numeral(
				purchaseInvoiceLinesArray.value.reduce(
					(acc, curr) => acc + curr.line_price_total_debit_exchange / 100,
					0,
				),
			)
				.format('0,0.00')
				.replace(regexComa, ' ')
				.replace(regexDot, ',')
		})
		return {
			columnsList,
			perPageProp,
			onCellClick,
			onRowDoubleClick,
			purchaseInvoiceLinesArray,
			purchaseInvoiceTotal,
			purchaseInvoiceExchangeUnit: computed(
				() => `  ${purchaseInvoiceExchangeUnit.value}`,
			),
		}
	},
}
</script>

<style scoped lang="scss"></style>
