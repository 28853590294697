<template>
	<div>
		<div class="margin-top-20" style="width: 90%">
			<b-tag
				type="is-info"
				size="is-large"
				class="margin-top-20"
				style="width: 420px"
			>
				Search stock item
			</b-tag>
		</div>
		<div v-if="loadingStockItems" class="loading-tag">Loading...</div>
		<div v-if="!loadingStockItems">
			<div class="margin-top-20">
				<ve-table
					:rows="listArr"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:perPage="perPageProp"
					maxHeight="350px"
				>
				</ve-table>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import storePurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState.js'
import storeSearchStockItemState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/search-stock-item/useSearchStockItemState.js'
import usePurchaseInvoiceModalState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/add-purchase-invoice-lines/usePurchaseInvoiceModalState.js'
// import usePurchaseInvoiceModalStateEdit from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/add-purchase-invoice-lines-edit/usePurchaseInvoiceModalStateEdit.js'
import SearchStockItemQuery from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/search-stock-item/SearchStockItemQuery.graphql'
export default {
	name: 'SearchStockItemToAddToPurchaseInvoiceEdit',
	setup() {
		const perPageProp = ref(10)
		const {
			ourCompanyPurchaseInvoice,
			customerIdPurchaseInvoice,
			purchaseInvoiceExchangeRate,
			purchaseInvoiceExchangeUnit,
		} = storePurchaseInvoiceHeaders()
		const labelPosition = ref('on-border')
		// const ourCompany = computed(() => Store.getters.getUserCurrentCompany)
		// ------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchStockItems, loading: loadingStockItems } = useQuery(
			SearchStockItemQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// Create Table
		const tableValues = ref([])
		const getData = () => {
			tableValues.value = []
			refetchStockItems().then((result) => {
				tableValues.value = result.data.stock.map((item) => {
					return {
						stockId: item.stock_id,
						itemName: item.stock_name,
						amount:
							item.outstanding_balance + item.debit_amount - item.credit_amount,
						stockUnit: item.stock_unit,
					}
				})
			})
		}
		const listArr = computed(() => tableValues.value)
		// -------------------------------------------------------------------------
		const { purchaseInvoiceModalState } = usePurchaseInvoiceModalState()
		const setPurchaseInvoiceModalState = (item) => {
			console.log('selected stock item', item)
			purchaseInvoiceModalState.ourCompany = ourCompanyPurchaseInvoice
			purchaseInvoiceModalState.customerId = customerIdPurchaseInvoice
			purchaseInvoiceModalState.stockId = item.stockId
			purchaseInvoiceModalState.lineInfo = `${item.stockId} - ${item.itemName} - ${item.stockUnit}`
			purchaseInvoiceModalState.exchangeRate = purchaseInvoiceExchangeRate.value
			purchaseInvoiceModalState.exchangeUnit = purchaseInvoiceExchangeUnit.value
			purchaseInvoiceModalState.isModalVisible = true
			purchaseInvoiceModalState.isAddOrEdit = 'edit'
			purchaseInvoiceModalState.keyModal =
				purchaseInvoiceModalState.keyModal + 1
			console.log('purchaseInvoiceModalState', purchaseInvoiceModalState)
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				const item = params.row
				console.log(item)
				setPurchaseInvoiceModalState(item)
			}
		}
		const onRowDoubleClick = (params) => {
			console.log(params.row)
		}
		// -------------------------------------------------------------------------
		const { columnsList } = storeSearchStockItemState()
		// -------------------------------------------------------------------------
		onMounted(() => {
			alert('SearchStockItemToAddPurchaseInvoiceEdit is fired')
			tableValues.value = []
			getData()
		})
		return {
			perPageProp,
			labelPosition,
			getData,
			listArr,
			columnsList,
			loadingStockItems,
			onCellClick,
			onRowDoubleClick,
		}
	},
}
</script>

<style scoped>
.loading-tag {
	font-size: 2em;
	font-weight: bolder;
	color: #9b1c1c;
}
</style>
