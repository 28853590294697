<!-- @format -->

<template>
	<layout-default-new>
		<div>
			<div>
				<sub-navbar page="Purchase Invoice" />
			</div>
			<div class="margin-top-20" v-if="showSearchBox">
				<ve-table
					:columns="columns"
					:rows="rows"
					:searchedOptions="true"
					:sort-options="sortOptions"
					:onRowDoubleClick="onRowDoubleClick"
					:onCellClick="onCellClick"
				>
				</ve-table>
			</div>
			<div v-if="showPreview">
				<PreviewSelectedPurchaseInvoice
					:refetchData="refresh"
					:closePreview="closePreview"
				/>
			</div>
			<div v-if="showSelectPurchaseInvoiceHeadersDetails">
				<EditSelectedPurchaseInvoice :refetchData="refresh" />
			</div>
		</div>
	</layout-default-new>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import PreviewSelectedPurchaseInvoice from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/PreviewSelectedPurchaseInvoice.vue'
import GetPurchaseInvoicesQuery from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/graphql/GetPurchaseInvoicesQuery.graphql'
import { useQuery } from '@vue/apollo-composable'
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import Store from '@/store'
import storePurchaseInvoiceHeaders from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState.js'
import storePurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/usePurchaseInvoiceState'
import EditSelectedPurchaseInvoice from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/EditSelectedPurchaseInvoice.vue'
import SetUnlockedPurchaseInvoiceMutation from '@/_srcv2/pages/purchase-invoice/_shared/SetUnlockedPurchaseInvoiceMutation.graphql'
import { useMutation } from '@vue/apollo-composable'

export default {
	name: 'EditPurchaseInvoice',
	components: {
		SubNavbar,
		EditSelectedPurchaseInvoice,
		PreviewSelectedPurchaseInvoice,
	},
	setup() {
		// * ---------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch } = useQuery(
			GetPurchaseInvoicesQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// * ----------------------------------------------------------------------------
		const rows = ref([])
		const columns = ref([])
		const mapData = (array) => {
			console.log('array', array)
			return array.map((item) => {
				console.log('item', item)
				return {
					invoiceDate: item.invoice_date,
					invoiceNumber: item.invoice_number,
					invoiceType: item.invoice_type,
					customer: `${item.customer.customer_id} - ${item.customer.customer_title} - ${item.customer.customer_nickname}`,
					customerId: item.customer.customer_id,
					customerTitle: item.customer.customer_title,
					customerNickname: item.customer.customer_nickname,
					invoiceDueDate: item.invoice_due_date,
					invoiceExchangeRate: item.invoice_exchange_rate,
					invoiceExchangeUnit: item.invoice_exchange_unit,
					isLocked: item.invoice_lock,
				}
			})
		}
		const setData = (result) => {
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					console.log('--- result', result)
					const tableRows = mapData(result)
					resolve(tableRows)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const refresh = () => {
			console.log('refresh is fired')
			showPreview.value = false
			refetch()
				.then((result) => setData(result.data.document_transactions))
				.then((tableRows) => (rows.value = tableRows))
				.then(() => console.log('rows.value', rows.value))
		}
		// ----------------------------------------------------------------------
		onMounted(() => {
			refresh()
		})
		// --------------------------------------------------------------------------
		columns.value = [
			{
				label: 'Preview',
				field: 'preview',
				width: '85px',
				sortable: false,
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '210px',
			},
			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '110px',
			},
			{
				label: 'Invoice Type',
				field: 'invoiceType',
				width: '100px',
			},
			{
				label: 'Supplier',
				field: 'customer',
				width: '440px',
			},
			{
				label: 'Unlock & Edit',
				field: 'unlock',
				width: '85px',
				sortable: false,
			},
		]
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'invoiceDate', type: 'desc' },
		}
		// todo ----------------------------------------------------------------
		const mutationVariable = computed(() => {
			return {
				invoice_number: invoiceNumberVar.value,
				our_company: Store.getters.getUserCurrentCompany,
			}
		})
		const invoiceNumberVar = ref('')
		const { mutate: unLockPurchaseInvoice } = useMutation(
			SetUnlockedPurchaseInvoiceMutation,
			() => ({
				variables: mutationVariable.value,
			}),
		)
		// * ----------------------------------------------------
		const onCellClick = (params) => {
			if (
				params.column.field === 'invoiceNumber' ||
				params.column.field === 'preview'
			) {
				console.log(params.row)
				setPurchaseInvoiceHeaders(params.row).then(() =>
					setComponentStatePreview(),
				)
			} else if (params.column.field === 'unlock') {
				invoiceNumberVar.value = params.row.invoiceNumber
				console.log('inside unlock', params.row)
				if (params.row.isLocked === true) {
					unLockPurchaseInvoice().then(() => {
						setPurchaseInvoiceHeaders(params.row).then(() =>
							setComponentState(),
						)
					})
				} else {
					setPurchaseInvoiceHeaders(params.row).then(() => setComponentState())
				}
			}
		}
		const onRowDoubleClick = (params) => {
			console.log(params.row)
		}
		// * -----------------------------------------------------
		const {
			ourCompanyPurchaseInvoice,
			customerIdPurchaseInvoice,
			customerTitlePurchaseInvoice,
			customerNicknamePurchaseInvoice,
			purchaseInvoiceIssueDate,
			purchaseInvoiceDueDate,
			purchaseInvoiceType,
			purchaseInvoiceExchangeUnit,
			purchaseInvoiceExchangeRate,
			purchaseInvoiceSupplierNumber,
			invoiceLock,
		} = storePurchaseInvoiceHeaders()
		// ! -----------------------------------------------------
		const {
			showSearchBox,
			showSelectPurchaseInvoiceHeadersDetails,
			showPreview,
		} = storePurchaseInvoice()
		// * -----------------------------------------------------
		const setPurchaseInvoiceHeaders = (row) => {
			return new Promise((resolve, reject) => {
				console.log('setPurchaseInvoiceHeaders row', row)
				if (row !== null || undefined) {
					ourCompanyPurchaseInvoice.value = Store.getters.getUserCurrentCompany
					customerIdPurchaseInvoice.value = row.customerId
					customerTitlePurchaseInvoice.value = row.customerTitle
					customerNicknamePurchaseInvoice.value = row.customerNickname
					purchaseInvoiceSupplierNumber.value = row.invoiceNumber
					purchaseInvoiceIssueDate.value = row.invoiceDate
					purchaseInvoiceDueDate.value = row.invoiceDueDate
					purchaseInvoiceType.value = row.invoiceType
					purchaseInvoiceExchangeUnit.value = row.invoiceExchangeUnit
					purchaseInvoiceExchangeRate.value = row.invoiceExchangeRate
					invoiceLock.value = false
					resolve(true)
				} else {
					const reason = new Error('Data could not be fetched from table')
					reject(reason)
				}
			})
		}
		// *-------------------------------------------------------------------
		const setComponentState = () => {
			showSearchBox.value = false
			showSelectPurchaseInvoiceHeadersDetails.value = true
		}
		const setComponentStatePreview = () => {
			showSearchBox.value = false
			showPreview.value = true
		}
		const closePreview = () => {
			showSearchBox.value = true
			showPreview.value = false
		}
		return {
			showPreview,
			closePreview,
			loading,
			refresh,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			showSearchBox,
			showSelectPurchaseInvoiceHeadersDetails,
		}
	},
}
</script>

<style lang="scss" scoped></style>
